<template>
  <div class="questionBank">
    <div class="room" v-if="list && list.length > 0">
      <div class="item-box" v-for="(item, index) in list" :key="index" @click="tabId = item.id">
        <div class="item">
          <img class="item-image" src="@/assets/img/homeSeventh/roomIcon.png" alt="" />
          <div class="item-content">
            <div class="item-title elp">
              {{ item.name }}
            </div>
            <div class="item-tips">
              <div class="tips-text">{{ item.roomPaperSize }}套试卷</div>
              <div class="m-r-4 m-l-4">/</div>
              <div class="tips-text">
                {{ item.kindName }}{{ item.majorName }}
              </div>
            </div>
            <div class="item-tips">{{ item.startTime }}-{{ item.endTime }}</div>
          </div>
          <div v-if="(item.type == 1 || item.showStatus == 1) &&
            (item.roomStatus == 2 || item.roomStatus == 3)
            " :class="item.gray ? 'learn-hui' : ''" class="colleges-learn" @click="goRoom(item)">
            进入考场
          </div>
          <div v-else-if="item.roomStatus == 1 || item.type == 2" @click="expired(item)" class="colleges-learn learn-hui">
            进入考场
          </div>
        </div>
      </div>
    </div>
    <div class="nothing" v-if="!list || list.length == 0">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <el-pagination class="pagination colleges-pagination" :total="total" :current-page="pageNum"
      :page-sizes="[8, 16, 24, 40]" :page-size="pageSize" :pager-count="5" @current-change="handleCurrentChange"
      @size-change="handleSizeChange" background layout="total, sizes, prev, pager, next">
    </el-pagination>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
import { AnswerClient } from "@/api/task/answerClient";
import { format, compareAsc, parseISO } from "date-fns";
let answerClient = new AnswerClient();
import { roomType } from "@/api/emun";
export default {
  components: {},

  data() {
    return {
      mTypeEnum: roomType,
      list: [], //查询列表
      getInfo: null,
      pageSize: 8,
      pageNum: 1,
      total: 0,
    };
  },
  async created() {
    this.userInfo = getInfo();
    this.getList();
  },
  methods: {
    /* 获取列表 */
    getList() {
      /* 虚拟考场 */
      answerClient
        .getPcMyRoom(
          this.pageSize,
          this.pageNum,
          undefined,
          undefined,
          this.userInfo ? this.userInfo.id : undefined
        )
        .then((res) => {
          res.rows.forEach((element) => {

            if (compareAsc(parseISO(element.endTime), new Date())) {
              element.gray = true;
            }
          });
          this.list = res.rows;
          this.total = res.total;
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    goRoom(item) {
      let sub = {
        roomId: item.id,
        classId: item.classId,
      };
      let data = Vue.prototype.export(sub);
      this.$router.push(`/quesTion/examRoom/examRoomDetail?${data}`);
    },
    expired(item) {

      if (item.roomStatus == 1) {
        this.$message({
          type: "warning",
          message: "考场未开始",
        });
      } else if (item.roomStatus == 3) {
        this.$message({
          type: "warning",
          message: "考场已结束",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.questionBank {
  background: #ffffff;
  padding-bottom: 20px;
}

/* 虚拟考场 */
.room {
  width: 100%;
  padding: 0px 0px 20px;

  .item-box {
    .item {
      width: 100%;
      display: flex;
      background: #ffffff;
      padding: 24px 0px 16px;
      margin-bottom: 0px;
      align-items: flex-start;
      border-bottom: 1px solid #eeeeee;
      position: relative;

      .item-image {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        margin-right: 12px;
      }

      .item-content {
        width: 100%;

        .item-title {
          width: 500px;
          height: 16px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
        }

        .item-num {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          margin-top: 8px;
        }

        .item-tips {
          margin-top: 16px;
          display: flex;
          align-items: center;
          height: 12px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 12px;

          .tips-text {
            height: 12px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }
      }
    }
  }
}

.learn-hui {
  color: #999999 !important;
  border-color: #999 !important;
}
</style>
